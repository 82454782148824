import { createSignal } from 'solid-js';

const [locale, setLocale] = createSignal(navigator.language);

const sids = JSON.parse(LOCALE_SIDS || {});

const getstr = (s) => sids[s] ? sids[s][locale()] || sids[s][locale()?.split('-')[0]] || s : s;

export { locale, setLocale, getstr, getstr as ls };

export default (props) => {
    return (
        <div>
            <select class="bg-neutral-full bg-opacity-55 outline-none" onChange={({ target }) => setLocale(target.value)}>
                {Object.keys(sids['Languages'])?.map((v) => <option value={v} selected={v == locale() || v == locale()?.split('-')[0]}>{`${sids['Languages'][v]} (${v})`}</option>)}
            </select>
        </div>
    );
}