import { createEffect, createResource, createSignal, mergeProps, Show } from "solid-js";
import Spinner from "./Spinner";
import Locale, { ls } from './Locale';

export default () => {
    const [step, setStep] = createSignal('email');
    const [error, setError] = createSignal();
    const [email, setEmail] = createSignal();
    const [password, setPassword] = createSignal();
    const [user, setUser] = createSignal();
    const [loading, setLoading] = createSignal();

    const emailStep = async () => {
        try {
            setLoading(true);
            const req = await fetch(`/find?k=email&v=${email()}`);
            setLoading(false);
            if (!req.ok) {
                setError(req.status == 404 ? 'Account does not exist.' : 'Something went wrong.');
                return;
            }
            setError();
            setUser(await req.json());
            setStep('password');
        } catch {
            setError('Unexpected error.')
        }
    };

    const passwordStep = async () => {
        setLoading(true);
        const req = await fetch(`/login`, { method: 'POST', headers: { 'content-type': 'application/json' }, body: JSON.stringify({ email: email(), password: password() }) });
        setLoading(false);
        if (!req.ok) {
            setError(req.status == 401 ? 'Invalid password.' : 'Something went wrong.');
            return;
        }
        setError();
        const params = new URLSearchParams(window.location.search);
        window.location.href = params.get('redirect_uri') || DEFAULT_URL;
    };

    createEffect(() => {
        switch (step()) {
        case 'email':
            setEmail();
            setUser();
            break;
        case 'password':
            setPassword();
            break;
        }
        setError();
    });

    return (
        <div class="w-svw h-svh flex flex-col items-center justify-center bg-neutral-900">
            <div class="p-4 m-4 w-2/5 max-md:w-5/6 shadow-md rounded-md bg-black text-white">
                <div class="flex flex-row items-center justify-between">
                    <div>
                        <h1 class="text-2xl font-bold">{ls('Login')}</h1>
                        <Show when={user()}>
                            <span class="italic">{ls('Welcome back')}, <span class="inline-flex flex-row items-center justify-center"><span>{user()?.name}</span><img class="ml-1 w-4 h-4" src={user()?.pfp}/></span>!</span>
                        </Show>
                        <Show when={!user()}>
                            <span class="italic">{ls('Welcome back')}!</span>
                        </Show>
                    </div>
                    <img src="logo.png" class="w-14 h-14"/>
                </div>
                <Show when={error()}>
                    <div class="my-2 w-full p-2 rounded-md bg-red-700 bg-opacity-45">
                        <span class="font-bold">{ls('Error')}:</span>
                        <p class="italic">{ls(error())}</p>
                    </div>
                </Show>
                <div>
                    <Show when={step() == 'recovery'}>
                        
                    </Show>
                    <Show when={step() == 'email'}>
                        <div class="mt-2">
                            <div>
                                <div class="my-2">
                                    <span>{ls('Email')}:</span>
                                </div>
                                <input onInput={({ target }) => setEmail(target.value)} placeholder="email@email.com" class="w-full outline-none bg-neutral-900 p-2 rounded-md" type="email" autocomplete="email"/>
                            </div>  
                        </div>
                        <div class="mt-2 flex flex-row items-center justify-end">
                            <Show when={loading()}>
                                <Spinner class="w-10 h-10"/>
                            </Show>
                            <Show when={!loading()}>
                                <button onClick={emailStep} class="bg-neutral-900 p-2 px-4 rounded-md">{ls('Next')}</button>
                            </Show>
                        </div>
                    </Show>
                    <Show when={step() == 'password'}>
                        <div class="mt-2">
                            <div>
                                <div class="my-2 flex flex-row items-center justify-start">
                                    <span>{ls('Password')}:</span>
                                    <span class="ml-2 italic text-neutral-400">{email()}</span>
                                </div>
                                <input onInput={({ target }) => setPassword(target.value)} placeholder={`${ls('Password')}...`} class="w-full outline-none bg-neutral-900 p-2 rounded-md" type="password" autocomplete="current-password"/>
                            </div>
                        </div>
                        <div class="mt-2 flex flex-row items-center justify-between">
                            <button onClick={() => setStep('recovery')}>{ls('Reset Password')}</button>
                            <div class="flex flex-row items-center justify-center">
                                <Show when={loading()}>
                                    <Spinner class="w-10 h-10"/>
                                </Show>
                                <Show when={!loading()}>
                                    <button onClick={() => setStep('email')} class="p-2 px-4 rounded-md mr-2" style="box-shadow: inset 0 0 0 2px rgb(23 23 23)">{ls('Back')}</button>
                                    <button onClick={passwordStep} accessKey="Enter" class="bg-neutral-900 p-2 px-4 rounded-md">{ls('Next')}</button>
                                </Show>
                            </div>
                        </div>
                    </Show>
                    <Show when={step() == '2fa'}>
                        
                    </Show>
                </div>
            </div>
            <div class="absolute bottom-0 left-0">
                <Locale/>
            </div>
        </div>
    );
}